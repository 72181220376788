import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { formatNumber } from "../../../utils/dataTransformer";
import { useCallback, useEffect, useState } from "react";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import { ITEMS_SKU_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";
import { useSearchParams } from "react-router-dom";

interface Props {
  data: any;
  start_no: number;
  isPurchase?: boolean;
  bgColor?: string;
  isInternational?: boolean;
  hasImage?: boolean;
  exchange_rate?: number;
}

const PDFTable = ({
  data,
  start_no,
  isPurchase,
  bgColor,
  hasImage = false,
  isInternational = false,
  exchange_rate = 1,
}: Props) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const [itemList, setItemList] = useState(data);

  const header = !isInternational
    ? !hasImage
      ? [
          {
            label: "No.",
            thaiLabel: " ",
            width: 20,
          },
          {
            label: "Name",
            thaiLabel: "รายการ",
            width: 250,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 40,
          },
          {
            label: "Price/Unit",
            thaiLabel: "ราคา/หน่วย",
            width: 65,
          },
          {
            label: "Discount",
            thaiLabel: type ? "ส่วนลด/หน่วย" : "ส่วนลด",
            width: type ? 70 : 50,
          },
          {
            label: "Vat",
            thaiLabel: "ภาษี",
            width: 40,
          },
          {
            label: "Pre-vat Amount",
            thaiLabel: "มูลค่าก่อนภาษี",
            width: 75,
          },
        ]
      : [
          {
            label: "No.",
            thaiLabel: " ",
            width: 20,
          },
          {
            label: "Name",
            thaiLabel: "รายการ",
            width: 270,
          },
          {
            label: "Qty",
            thaiLabel: "จำนวน",
            width: 40,
          },
          {
            label: "Price/Unit",
            thaiLabel: "ราคา/หน่วย",
            width: 65,
          },
          {
            label: "Discount",
            thaiLabel: "ส่วนลด",
            width: 50,
          },
          {
            label: "Vat",
            thaiLabel: "ภาษี",
            width: 40,
          },
          {
            label: "Pre-vat Amount",
            thaiLabel: "มูลค่าก่อนภาษี",
            width: 75,
          },
        ]
    : [
        {
          label: "No.",
          thaiLabel: " ",
          width: 20,
        },
        {
          label: "Image",
          thaiLabel: " ",
          headerWidth: 33,
        },
        {
          label: "Name",
          thaiLabel: "Item",
          width: 250,
        },
        {
          label: "Qty",
          thaiLabel: "Qty",
          width: 40,
        },
        {
          label: "Price/Unit",
          thaiLabel: "Price/Unit",
          width: 65,
        },
        {
          label: "Amount (Ex-VAT)",
          thaiLabel: "Amount (Ex-VAT)",
          width: 90,
        },
        {
          label: "Amount in THB",
          thaiLabel: "Amount in THB",
          width: 90,
        },
      ];

  const getSkuFormItem = useCallback(async () => {
    const allItemListUniqueId = data.map((item: any) => item.item_unique_id);
    const graphQLClientWithHeaderItem: GraphQLClient =
      createGraphQLClientWithMiddleware("item");

    const { itemSkuDetailsFindManyAggrid } =
      await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
        aggridInput: {
          startRow: 0,
          endRow: 999,
          filterModel: {
            sku_name: {
              filterType: "set",
              values: allItemListUniqueId,
            },
          },
        },
      });
    const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

    const newItemList = data
      .filter((list: any) => !list.is_child)
      .map((item: any) => {
        const foundItemIndex = itemSkuDetails.findIndex(
          (realItem: any) => realItem.sku_name === item.item_unique_id
        );

        return {
          ...item,
          item_unique_id:
            itemSkuDetails[foundItemIndex]?.item_sku?.item?.unique_id,
          item_sku_name: item.item_unique_id,
          seller_sku_name: itemSkuDetails[foundItemIndex]?.seller_sku_name,
        };
      });

    setItemList(newItemList);
  }, [data]);

  useEffect(() => {
    if (isInternational) {
      getSkuFormItem();
    }
  }, [getSkuFormItem, isInternational]);

  const renderTableBody = (isInternational: boolean) => {
    if (!isInternational) {
      return (
        <TableBody>
          {data &&
            data
              .filter((list: any) => !list.is_child)
              .map((item: any, index: number) => (
                <TableRow>
                  <TableCell
                    key={item.item_unique_id}
                    sx={{
                      breakInside: "avoid",
                      height: "50px",
                      verticalAlign: "top",
                    }}
                  >
                    <Typography fontSize={11}>{start_no + index}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      py: !hasImage ? 1 : "2px !important",
                      px: 0.25,
                      maxWidth: "295px",
                    }}
                  >
                    {hasImage ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {item.item_img_url[0] ? (
                            <img
                              alt={`item's ${start_no + index}`}
                              src={item.item_img_url[0]}
                              width={72}
                              height={72}
                            />
                          ) : (
                            <Box width={72} height={72} />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            py: "6px",
                          }}
                        >
                          <Typography
                            fontSize={11}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {item.item_unique_id} - {item.item_name}
                          </Typography>
                          <Typography
                            fontSize={11}
                            sx={{
                              wordBreak: "break-word",
                              whiteSpace: "pre-wrap",
                              color: "#737373",
                            }}
                          >
                            {item.item_sku_desc}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Typography
                          fontSize={11}
                          fontWeight={600}
                          sx={{ wordBreak: "break-word" }}
                        >
                          {item.item_unique_id} - {item.item_name}
                        </Typography>
                        <Typography
                          fontSize={11}
                          sx={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {item.item_sku_desc}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={11}>{item.qty}</Typography>
                    <Typography fontSize={9} color="#737373">
                      {item.uom}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={11}>
                      {item.price_per_unit
                        ? formatNumber(item.price_per_unit)
                        : formatNumber(0)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={11}>
                      {item.discount
                        ? formatNumber(item.discount)
                        : formatNumber(0)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={11}>
                      {item.vat_percentage !== "ไม่มี"
                        ? item.vat_percentage + "%"
                        : "ไม่ระบุ"}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      py: 1,
                      px: 0.25,
                    }}
                  >
                    <Typography fontSize={11}>
                      {item.pre_discount_amount
                        ? formatNumber(item.pre_discount_amount)
                        : item.pre_vat_amount
                        ? formatNumber(item.pre_vat_amount)
                        : formatNumber(0)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          {itemList &&
            itemList.length > 0 &&
            itemList.map((item: any, index: number) => (
              <TableRow
                key={item.item_unique_id}
                sx={{
                  breakInside: "avoid",
                  height: "50px",
                  verticalAlign: "top",
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>{start_no + index}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    verticalAlign: "middle !important",
                    padding: "0px !important",
                  }}
                >
                  {item.item_img_url[0] && (
                    <img
                      alt={`item's ${start_no + index}`}
                      src={item.item_img_url[0]}
                      style={{
                        display: "block",
                        border: "1px solid #E2E2E2",
                        borderRadius: "4px",
                        padding: 0,
                      }}
                      width={30}
                      height={30}
                    />
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    py: 1,
                    px: 0.25,
                    maxWidth: "295px",
                  }}
                >
                  <Typography
                    fontSize={11}
                    fontWeight={600}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {item.seller_sku_name && item.seller_sku_name}
                    {item.item_sku_desc &&
                      `${item.seller_sku_name && " - "}${item.item_sku_desc}`}
                  </Typography>
                  <Typography
                    fontSize={11}
                    sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
                  >
                    {item.item_unique_id} - {item.item_name}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>{item.qty}</Typography>
                  <Typography fontSize={9} color="#737373">
                    {item.uom}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.price_per_unit
                      ? formatNumber(item.price_per_unit)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.pre_discount_amount
                      ? formatNumber(item.pre_discount_amount)
                      : item.pre_vat_amount
                      ? formatNumber(item.pre_vat_amount)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    py: 1,
                    px: 0.25,
                  }}
                >
                  <Typography fontSize={11}>
                    {item.pre_discount_amount
                      ? formatNumber(item.pre_discount_amount * exchange_rate)
                      : item.pre_vat_amount
                      ? formatNumber(item.pre_vat_amount * exchange_rate)
                      : formatNumber(0)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      );
    }
  };

  return (
    <TableContainer
      sx={{
        minHeight: !isInternational ? "480px" : "430px",
        maxHeight: !isInternational ? "850px" : "740px",
      }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map((head, index) => (
              <TableCell
                align={head.label === "Name" ? "left" : "right"}
                key={index}
                sx={{
                  pr: head.label === "Name" ? "" : 0,
                  pl:
                    head.label === "Amount in THB"
                      ? 0
                      : head.label === "Name"
                      ? 1
                      : "",
                  py: 1,
                  backgroundColor: isPurchase
                    ? "#FCE8E8"
                    : bgColor
                    ? bgColor
                    : "#ECF6FD",
                  borderTopLeftRadius: index === 0 ? 4 : 0,
                  borderBottomLeftRadius: index === 0 ? 4 : 0,
                  borderTopRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottomRightRadius: index === header.length - 1 ? 4 : 0,
                  borderBottom: "none",
                }}
                width={head.headerWidth}
              >
                <Typography width={head.width} fontSize={11} fontWeight={600}>
                  {head.thaiLabel}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {renderTableBody(isInternational)}
      </Table>
    </TableContainer>
  );
};

export default PDFTable;
