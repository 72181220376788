import { gql } from "graphql-request";

export const GET_EXPORTED_REPORT = gql`
  query GenerateReportNoQueue(
    $reportType: InventoryReportType
    $aggridInput: AggridTemplateInput
  ) {
    GenerateReportNoQueue(reportType: $reportType, aggridInput: $aggridInput)
  }
`;

export const GENERATE_REPORT = gql`
  mutation GenerateReport(
    $priority: Int!
    $params: Json
    $reportType: InventoryReportType
  ) {
    GenerateReport(
      priority: $priority
      params: $params
      reportType: $reportType
    ) {
      success
      timestamp
      status
      message
      jobId
      detail
    }
  }
`;

export const GET_STOCK_REPORT_VIEW = gql`
  query StockReportViews($aggridInput: AggridTemplateInput) {
    StockReportViews(aggridInput: $aggridInput) {
      data {
        unique_id
        img_url
        name
        purchase_ordered_qty
        sale_committed_qty
        stock_qty
        available_qty
        manufacture_ordered_qty
        manufacture_committed_qty
        sku_name
        item_group_level_1
        item_group_level_2
        item_group_level_3
        purchase_price_per_unit
        total_purchase_amount
        sale_price_per_unit
        total_sale_amount
        minimum_stock
        maximum_stock
        purchase_minimum_qty
        recommended_purchase_qty
        recommended_purchase_amount
        stock_status
        item_status
      }
      count
    }
  }
`;

export const GET_STOCK_BY_LOT_REPORT_VIEW = gql`
  query StockByLotReportViews($aggridInput: AggridTemplateInput) {
    StockByLotReportViews(aggridInput: $aggridInput) {
      data {
        img_url
        unique_id
        sku_name
        item_group_level_1
        item_group_level_2
        item_group_level_3
        name
        warehouse_unique_id
        warehouse_name
        bin_location_id
        bin_location_name
        serial_no
        barcode
        lot
        purchase_ordered_qty
        sale_committed_qty
        stock_qty
        available_qty
        price_per_unit
        total_amount
        item_status
      }
      count
    }
  }
`;

export const GET_TRACE_ENTRY_REPORT_VIEW = gql`
  query TraceEntryReportViews($aggridInput: AggridTemplateInput) {
    TraceEntryReportViews(aggridInput: $aggridInput) {
      count
      data {
        id
        created_date
        posted_date
        item_unique_id
        item_name
        item_seller_sku
        movement_type
        document_type
        qty
        uom_name
        document_unique_id
        reference_unique_id
        source_warehouse_unique_id
        source_warehouse_name
        source_bin_location_id
        source_bin_location_name
        destination_warehouse_unique_id
        destination_warehouse_name
        destination_bin_location_id
        destination_bin_location_name
        serial_no
        barcode
        lot_date
        moved_by
        created_by
      }
    }
  }
`;

export const GET_MOVEMENT_SUMMARY_REPORT_VIEW = gql`
  query MovementSummaryReportViews($aggridInput: AggridTemplateInput) {
    MovementSummaryReportViews(aggridInput: $aggridInput) {
      count
      data {
        created_date
        source_warehouse_unique_id
        item_unique_id
        item_name
        import_purchase_order
        import_manufacture_order
        import_other
        import_sales_return
        import_adjust
        import_transfer
        import_sum
        export_sales_order
        export_manufacture_order
        export_other
        export_purchase_return
        export_adjust
        export_transfer
        export_sum
        total_sum
        available_qty
        stock_qty
        purchase_ordered_qty
        manufacture_ordered_qty
        sale_committed_qty
        manufacture_committed_qty
      }
    }
  }
`;
