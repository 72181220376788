import { Box, IconButton, Typography, TypographyProps } from "@mui/material";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { CustomizedTooltip } from "../../../Custom/CustomizedTooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { IDefaultForm } from "../../../../types/global";
import { ISalesItemList } from "../../../../types/Sales";
import ItemQty from "../../../UI/ItemQty";
import { useItemSkuQty } from "../../../../hooks/use-item-sku-qty";

interface Props {
  control: IDefaultForm["control"];
  errors: IDefaultForm["errors"];
  disabled: boolean;
  field: ISalesItemList;
  index: number;
  subIndex: number;
  documentType?: string;
}

const ItemBomNameCell = ({
  control,
  field,
  errors,
  index,
  disabled,
  subIndex,
  documentType = "sales_order",
}: Props) => {
  const { isSufficient, formatData, uom } = useItemSkuQty(
    control,
    subIndex,
    documentType,
    `item_list.${index}.sales_bom_item`
  );

  const itemNameProps: TypographyProps = {
    variant: "body2",
    align: "left",
    mt: 1,
    fontWeight: 600,
  };

  const itemUniqueIdProps: TypographyProps = {
    variant: "overline",
    sx: {
      my: 0,
      ml: !disabled ? 2 : 0,
      fontWeight: 600,
      cursor: "pointer",
    },
    color: "primary",
    onClick: () =>
      window.open(
        `/inventory/items/${field.item_unique_id}?tab=item&subtab=general`,
        "_blank"
      ),
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "stretch",
          flex: 1,
          textAlign: "left",
        }}
      >
        {disabled ? (
          <Typography {...itemNameProps}>{field.item_name}</Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            control={control}
            error={Boolean(
              errors?.item_list &&
                errors?.item_list[index] &&
                errors?.item_list[index]?.item_name
            )}
            name={`item_list.${index}.sales_bom_item.${subIndex}.item_name`}
            sx={{ mt: 1 }}
          />
        )}
        <Typography {...itemUniqueIdProps}>{field.item_unique_id}</Typography>
        {disabled ? (
          <Typography
            variant="body2"
            align="left"
            gutterBottom
            sx={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              color: "#737373",
            }}
          >
            {field.item_sku_desc}
          </Typography>
        ) : (
          <ControlledTextField
            disabled={disabled}
            control={control}
            name={`item_list.${index}.sales_bom_item.${subIndex}..item_sku_desc`}
            multiline
            minRows={1}
            maxRows={10}
            sx={{ mb: 1, width: "327px" }}
            error={Boolean(errors?.item_list?.[index]?.item_sku_desc)}
            helperText={errors?.item_list?.[index]?.item_sku_desc?.message}
            onChange={(e, field) => {
              const inputValue = e.target.value;
              const lines = inputValue.split("\n");
              if (lines.length <= 10) {
                field.onChange(inputValue);
              }
            }}
          />
        )}
      </Box>
      <CustomizedTooltip
        title={
          <ItemQty
            formatData={formatData}
            isSufficient={isSufficient}
            uom={uom}
          />
        }
      >
        <IconButton
          size="small"
          sx={{ color: isSufficient ? "#8FCBB1" : "#F44336", fontSize: "13px" }}
        >
          <CircleIcon fontSize="inherit" />
        </IconButton>
      </CustomizedTooltip>
    </Box>
  );
};

export default ItemBomNameCell;
