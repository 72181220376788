import { GraphQLClient } from "graphql-request";
import {
  PurchaseDocumentType,
  PurchaseRequestQuery,
  PriceVatType,
} from "../../generated/purchase";
import { ITEMS_SKU_AGGRID } from "../../services/AgGrid/InventoryAgGrid";
import {
  IPurchaseRequest,
  IPurchaseRequestItemList,
} from "../../types/Purchase/purchaseRequest";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";

export const purchaseRequestCreatePayloadFormatter = (
  data: IPurchaseRequest,
  status: string
) => {
  const { id, item_list, created_date, ...otherData } = data;
  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseRequest,
      reference_unique_id: data.unique_id,
    })
  );

  const formatPayload = {
    ...otherData,
    item_list: formatItemList,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseRequestUpdatePayloadFormatter = (
  data: IPurchaseRequest,
  status: string,
  isNotApprove?: boolean
) => {
  const {
    id,
    unique_id,
    main_status,
    flag_status,
    aggrid_status,
    item_list,
    created_by,
    created_date,
    ...otherData
  } = data;

  const formatItemList = item_list.map(
    ({ uom_group, item_sku_qty, reference_line_item, ...otherItem }) => ({
      ...otherItem,
      reference_document_type: PurchaseDocumentType.PurchaseRequest,
      reference_unique_id: data.unique_id,
      reference_line_item: reference_line_item ?? undefined,
    })
  );

  const formatPayload = {
    ...otherData,
    flag_status:
      isNotApprove && flag_status
        ? !flag_status.find((fl) => fl === "not_approved")
          ? [...flag_status, "not_approved"]
          : flag_status
        : undefined,
    item_list: formatItemList,
    sub_status: status,
  };
  return formatPayload;
};

export const purchaseRequestQueryFormatter = async (data: IPurchaseRequest) => {
  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const allItemListUniqueId = data.item_list.map((item) => item.item_unique_id);
  const { itemSkuDetailsFindManyAggrid } =
    await graphQLClientWithHeaderItem.request(ITEMS_SKU_AGGRID, {
      aggridInput: {
        startRow: 0,
        endRow: allItemListUniqueId.length,
        filterModel: {
          sku_name: {
            filterType: "set",
            values: allItemListUniqueId,
          },
        },
      },
    });
  const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

  let formatItemList: IPurchaseRequestItemList[] = [];

  data.item_list.forEach((item) => {
    const foundItemIndex = itemSkuDetails.findIndex(
      (realItem: any) => realItem.sku_name === item.item_unique_id
    );

    formatItemList.push({
      ...item,
      uom_group: itemSkuDetails[foundItemIndex]?.item_sku.item.uom_group,
      item_sku_qty: {
        stock_qty: itemSkuDetails[foundItemIndex]?.stock_qty,
        available_qty: itemSkuDetails[foundItemIndex]?.available_qty,
        purchase_ordered_qty:
          itemSkuDetails[foundItemIndex]?.purchase_ordered_qty,
        manufacture_ordered_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_ordered_qty,
        sale_committed_qty: itemSkuDetails[foundItemIndex]?.sale_committed_qty,
        manufacture_committed_qty:
          itemSkuDetails[foundItemIndex]?.manufacture_committed_qty,
      },
    });
  });

  const formatPayload = {
    ...data,
    item_list: formatItemList,
  };
  return formatPayload;
};

export const copyPurchaseRequestFormatter = (
  data?: PurchaseRequestQuery["purchaseRequest"]
) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          qty_ordered,
          reference_document_type,
          reference_unique_id,
          reference_line_item,
          ...otherItemList
        }) => ({
          ...otherItemList,
          qty_ordered: 0,
        })
      );

    return {
      ...otherData,
      copied_id: id,
      copied_unique_id: unique_id,
      item_list: formatItemList,
    };
  }
};

export const createPurchaseOrderFromRequest = (data: IPurchaseRequest) => {
  if (data) {
    const {
      id,
      created_by,
      unique_id,
      created_date,
      issue_date,
      due_date,
      aggrid_status,
      main_status,
      sub_status,
      flag_status,
      item_list,
      updated_date,
      requestor_contact,
      ...otherData
    } = data;

    const formatItemList =
      item_list &&
      item_list.map(
        ({
          qty_ordered,
          reference_document_type,
          reference_unique_id,
          ...otherItemList
        }) => ({
          ...otherItemList,
          reference_line_item: {
            reference_document_type: PurchaseDocumentType.PurchaseRequest,
            reference_unique_id: otherItemList.unique_id,
          },
          pr_reference_unique_id: data.unique_id,
          qty_ordered: 0,
        })
      );

    return {
      ...otherData,
      currency: "THB",
      exchange_rate: 0,
      reference_id_list: [data.id],
      reference_unique_id_list: [data.unique_id],
      price_vat_type: PriceVatType.ExcludedVat,
      item_list: formatItemList,
    };
  }
};
