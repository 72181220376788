import { useCallback, useEffect, useMemo, useState } from "react";
import { IDefaultForm, IHeaderTable } from "../../types/global";
import { Theme, useMediaQuery } from "@mui/material";
import { ITraceEntry } from "../../types/Inventory";
import { Tracability } from "../../generated/inventory";

const defaultHeaders: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวนจากเอกสาร",
    width: 110,
    align: "right",
  },
  {
    thaiLabel: "จำนวนนำเข้าแล้ว",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "จำนวนรอนำเข้า",
    width: 100,
    align: "right",
  },

  {
    thaiLabel: "จำนวนที่จะนำเข้า",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    label: "",
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "หมายเหตุ",
    width: 150,
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
];

const defaultScanHeader: IHeaderTable[] = [
  {
    thaiLabel: "ลำดับ",
    width: 75,
  },
  {
    thaiLabel: "วันที่และเวลา",
    width: 120,
  },
  {
    thaiLabel: "รูป",
    width: 100,
  },
  {
    thaiLabel: "รหัสสินค้า - ชื่อสินค้า",
    width: 150,
    align: "left",
  },
  {
    thaiLabel: "จำนวน",
    width: 100,
    align: "right",
  },
  {
    thaiLabel: "หน่วย",
    width: 100,
  },
  {
    thaiLabel: "Batch/SN",
    width: 150,
  },
  {
    thaiLabel: "สถานที่",
    width: 200,
  },
  {
    thaiLabel: "",
    width: 75,
  },
  {
    thaiLabel: "",
    width: 20,
  },
  {
    thaiLabel: "ผู้สแกน",
    width: 100,
  },
  {
    thaiLabel: "Barcode",
    width: 300,
    align: "left",
  },
  {
    thaiLabel: "Lot",
    width: 100,
  },
  {
    thaiLabel: "พิมพ์",
    width: 60,
  },
];

export const useGoodsReceiveItemList = (step: number, disabled: boolean) => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (!disabled) {
      if (step === 1) {
        setHeaders([
          ...defaultHeaders,
          {
            label: "",
            thaiLabel: "",
            width: 20,
          },
        ]);
      } else {
        if (isMobile) {
          const newHeader = defaultScanHeader.filter(
            (_, index) => index !== 12
          );
          setHeaders(newHeader);
        } else {
          setHeaders([...defaultScanHeader]);
        }
      }
    } else {
      if (step === 1) {
        setHeaders(defaultHeaders);
      } else {
        const newHeader = defaultScanHeader.filter((_, index) => index !== 9);
        if (isMobile) {
          const newMobileHeader = newHeader.filter((_, index) => index !== 11);
          setHeaders(newMobileHeader);
        } else {
          setHeaders(newHeader);
        }
      }
    }
  }, [disabled, isMobile, step]);

  return headers;
};

export const useAutoGoodsReceiveHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders([
      {
        thaiLabel: "",
        width: 20,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        width: 120,
        align: "left",
      },
      {
        thaiLabel: "จากเอกสาร",
        width: 90,
        align: "right",
      },
      {
        thaiLabel: "นำเข้าแล้ว",
        width: 90,
        align: "right",
      },
      {
        thaiLabel: "รอนำเข้า",
        width: 90,
        align: "right",
      },
      {
        thaiLabel: "นำเข้า",
        width: 90,
        align: "right",
        required: true,
      },
      {
        thaiLabel: "หน่วย",
        width: 80,
        align: "left",
      },
      {
        thaiLabel: "Barcode",
        width: 100,
        align: "left",
      },
      {
        thaiLabel: "สถานที่",
        width: 130,
        align: "left",
        required: true,
      },
    ]);
  }, []);

  return headers;
};

export const useMultiScanBarcodeHeader = () => {
  const [headers, setHeaders] = useState<IHeaderTable[]>([]);

  useEffect(() => {
    setHeaders([
      {
        thaiLabel: "",
        width: 10,
      },
      {
        thaiLabel: "ชื่อสินค้า",
        width: 220,
        align: "left",
      },
      {
        thaiLabel: "จำนวน",
        width: 50,
        align: "right",
      },
      {
        thaiLabel: "Barcode",
        width: 220,
        align: "left",
      },
    ]);
  }, []);

  return headers;
};

export const useGoodsReceiveCheckboxTable = (
  getValues: IDefaultForm["getValues"]
) => {
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const handleSelectRow = useCallback(
    (unique_id: string) => {
      setSelectedRows((prevSelected) => {
        const toggleSelection = (set: Set<string>, id: string): Set<string> => {
          const newSet = new Set(set);
          if (set.has(id)) {
            newSet.delete(id);
          } else {
            newSet.add(id);
          }
          return newSet;
        };

        const areAllIdsSelected = (
          ids: string[],
          selected: Set<string>
        ): boolean => ids.every((id) => selected.has(id));

        const handleSerialListSelection = (
          serialIds: string[],
          isRemoving: boolean,
          currentSet: Set<string>
        ): Set<string> => {
          const newSet = new Set(currentSet);
          if (isRemoving) {
            serialIds.forEach((id) => newSet.delete(id));
          } else {
            serialIds.forEach((id) => newSet.add(id));
          }
          return newSet;
        };

        const trace_entry_list: ITraceEntry[] = getValues("trace_entry_list");
        const selectedTrace = trace_entry_list.find(
          (trace) => trace.unique_id === unique_id
        );

        let newSelected = toggleSelection(prevSelected, unique_id);

        if (!selectedTrace) {
          const foundTrace = trace_entry_list.find((trace) =>
            trace.serial_list?.some((serial) => serial.unique_id === unique_id)
          );

          if (foundTrace) {
            const serialIds =
              foundTrace.serial_list?.map((serial) => serial.unique_id) || [];
            const isAllSelected = areAllIdsSelected(serialIds, newSelected);

            if (isAllSelected) {
              newSelected.add(foundTrace.unique_id);
            } else {
              newSelected.delete(foundTrace.unique_id);
            }
          }

          return newSelected;
        }

        if (selectedTrace.tracability === Tracability.Normal) {
          return newSelected;
        }

        const serialIds =
          selectedTrace.serial_list?.map((serial) => serial.unique_id) || [];

        return handleSerialListSelection(
          serialIds,
          prevSelected.has(unique_id),
          newSelected
        );
      });
    },
    [getValues]
  );

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const trace_entry_list: ITraceEntry[] = getValues("trace_entry_list");
        const selectedSet = new Set<string>();

        trace_entry_list.forEach((trace) => {
          if (trace.serial_list && trace.serial_list.length > 0) {
            trace.serial_list.forEach((serial) =>
              selectedSet.add(serial.unique_id)
            );
            selectedSet.add(trace.unique_id);
          } else {
            selectedSet.add(trace.unique_id);
          }
        });

        setSelectedRows(selectedSet);
      } else {
        setSelectedRows(new Set());
      }
    },
    [getValues]
  );

  const isRowSelected = useMemo(
    () => (unique_id: string) => selectedRows.has(unique_id),
    [selectedRows]
  );

  const selectedRowsArray = useMemo(
    () => Array.from(selectedRows),
    [selectedRows]
  );

  return {
    selectedRows: selectedRowsArray,
    setSelectedRows,
    handleSelectRow,
    handleSelectAll,
    isRowSelected,
  };
};
