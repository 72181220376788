import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { GraphQLClient } from "graphql-request";
import { useState, useCallback, useEffect } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { IManufactureOrder } from "../../../types/Manufacture/order";

import Confirmation from "../../../components/UI/Confirmation";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import ManufactureOrderHeaderForm from "../../../components/Form/Manufacture/Order/Header";
import {
  manufactureOrderSchema,
  manufactureOrderValidation,
} from "../../../components/Form/Manufacture/Order/schema";
import ManufactureOrderRemarkForm from "../../../components/Form/Manufacture/Order/Remark";
import ManufactureOrderDetailForm from "../../../components/Form/Manufacture/Order/Detail";
import ManufactureOrderTableForm from "../../../components/Form/Manufacture/Order/Table";
import ManufactureOrderAttachment from "../../../components/Form/Manufacture/Order/Detail/Attachment";

import {
  ActivityType,
  BomQuery,
  ManufactureActivityLogDocumentType,
  ManufactureEntityType,
  ManufactureOrderQuery,
  Status,
  useBomQuery,
  useManufactureDocumentNextStatusMutation,
  useManufactureOrderCreateMutation,
  useManufactureOrderQuery,
  useManufactureOrderUpdateMutation,
} from "../../../generated/manufacture";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";

import { useConfirmation } from "../../../hooks/use-confrimation";
import { errorMessageFormatter } from "../../../utils/Formatter/Global";
import {
  copyManufactureOrderformatter,
  formatterManufactureOrder,
  formatQueryManufactureOrder,
} from "../../../utils/Formatter/ManufactureOrder";

import { IBom } from "../../../types/Manufacture/bom";
import { IIngredient } from "../../../types/Manufacture";
import { Box, CircularProgress, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useManufactureError } from "../../../hooks/Manufacture/use-manufacture-error";
import { useRoleSelfPermission } from "../../../hooks/use-role-permission";
import BottomNavbar from "../../../components/UI/Navbar/BottomNavbar";
import { useActivityLogCreate } from "../../../hooks/use-global-activity-log";
import LoadingMascot from "../../../components/UI/LoadingMascot";
import { useOnLeavePage } from "../../../hooks/use-on-leave-page";
import { roundingNumber } from "../../../utils/dataTransformer";
import { ITEMS_SKU_AGGRID } from "../../../services/AgGrid/InventoryAgGrid";

const DocumentInfoTab = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [changeStatus, setChangeStatus] = useState<boolean>(false);
  const [changeWorkOrderStatus, setChangeWorkOrderStatus] = useState(false);
  const [roundedProductionQty, setRoundedProductionQty] = useState(0);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [ingredientItemIds, setIngredientItemIds] = useState<string[]>([]);
  const [ingredientItemIdsSnapshot, setIngredientItemIdsSnapshot] = useState<
    string[]
  >([]);
  const [wasteProductionItemIds, setWasteProductionItemIds] = useState<
    string[]
  >([]);
  const [wasteProductionItemIdsSnapshot, setWasteProductionItemIdsSnapshot] =
    useState<string[]>([]);

  const [allLoading, setAllLoading] = useState<boolean>(false);

  const {
    control,
    setValue,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm<IManufactureOrder>({
    defaultValues: manufactureOrderSchema,
    resolver: yupResolver(manufactureOrderValidation),
    mode: "onChange",
  });

  const { manufactureActivityLogCreate } = useActivityLogCreate();
  const createdBy = useWatch({ control, name: "created_by" });

  useRoleSelfPermission(createdBy);

  const isAttachmentDirty = Array.isArray(dirtyFields?.attachment_list)
    ? dirtyFields.attachment_list.some((attachment) =>
        Object.values(attachment).some(
          (value) =>
            typeof value === "object"
              ? Object.values(value).some(Boolean) // Check nested objects
              : Boolean(value) // Check top-level fields
        )
      )
    : Boolean(dirtyFields?.attachment_list);

  const { allowNextNavigation } = useOnLeavePage(isAttachmentDirty);

  const {
    fields: ingredientFields,
    append: ingredientAppend,
    move: ingredientMove,
    replace: ingredientReplace,
    remove: ingredientRemove,
    // update: ingredientUpdate,
  } = useFieldArray({
    control,
    name: "ingredient_list",
  });

  const {
    fields: workOrderFields,
    append: workOrderAppend,
    move: workOrderMove,
    remove: workOrderRemove,
    update: workOrderUpdate,
    replace: workOrderReplace,
  } = useFieldArray({
    control,
    name: "work_order_list",
  });

  const watchBomName = useWatch({ control, name: "bom_name" });
  const status = useWatch({
    control,
    name: "aggrid_status",
  });

  const graphQLClientWithHeaderManufacture: GraphQLClient =
    createGraphQLClientWithMiddleware("manufacture");

  const {
    data: moData,
    isSuccess: isSuccessMo,
    isLoading,
    refetch,
  } = useManufactureOrderQuery<ManufactureOrderQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { unique_id: id },
    },
    { enabled: !!id }
  );

  const {
    data: bomData,
    refetch: bomRefetch,
    isLoading: isBomLoading,
  } = useBomQuery<BomQuery>(
    graphQLClientWithHeaderManufacture,
    {
      uniqueInput: { name: watchBomName },
    },
    {
      enabled: Boolean(watchBomName),
    }
  );

  const { mutateAsync: createMo, isLoading: isCreating } =
    useManufactureOrderCreateMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onMutate() {
          if (status && status !== "draft") setDisabled(true);
        },
        async onSuccess({ manufactureOrderCreate }) {
          if (changeStatus) {
            await nextStatusMo({
              documentInput: {
                unique_id: manufactureOrderCreate?.unique_id || "",
                reference_document_type: ManufactureEntityType.ManufactureOrder,
              },
            });
          } else {
            enqueueSnackbar("บันทึกร่างสำเร็จ", {
              variant: "success",
            });
          }
          allowNextNavigation();
          navigate(`/manufacture/order/${manufactureOrderCreate?.unique_id}`);
        },
        onError(error) {
          const duplicatedUniqueId = errorMessageFormatter(error);
          if (duplicatedUniqueId) {
            enqueueSnackbar(duplicatedUniqueId, {
              variant: "error",
            });
          } else {
            if (changeStatus) {
              enqueueSnackbar("ยืนยันไม่สำเร็จ", {
                variant: "error",
              });
            } else {
              enqueueSnackbar("บันทึกร่างไม่สำเร็จ", {
                variant: "error",
              });
            }
          }
          setDisabled(false);
        },
      }
    );

  const { mutateAsync: updateMo, isLoading: isUpdating } =
    useManufactureOrderUpdateMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onMutate() {
          if (status && status !== "draft") setDisabled(true);
        },
        async onSuccess({ manufactureOrderUpdate }) {
          if (changeWorkOrderStatus) {
            enqueueSnackbar("เปลี่ยนสถานะขั้นตอนงานสำเร็จ", {
              variant: "success",
            });
            setChangeWorkOrderStatus(false);
          } else if (changeStatus) {
            nextStatusMo({
              documentInput: {
                unique_id: manufactureOrderUpdate?.unique_id || "",
                reference_document_type: ManufactureEntityType.ManufactureOrder,
              },
            });
          } else {
            enqueueSnackbar("แก้ไขใบสั่งผลิตสำเร็จ", {
              variant: "success",
            });
          }
          await refetch();
        },
        onError() {
          if (changeWorkOrderStatus) {
            enqueueSnackbar("เปลี่ยนสถานะขั้นตอนงานไม่สำเร็จ", {
              variant: "error",
            });
            setChangeWorkOrderStatus(false);
          } else {
            enqueueSnackbar("แก้ไขใบสั่งผลิตไม่สำเร็จ", {
              variant: "error",
            });
          }
          setDisabled(false);
        },
      }
    );

  const { mutateAsync: nextStatusMo, isLoading: isChangingStatus } =
    useManufactureDocumentNextStatusMutation<Error>(
      graphQLClientWithHeaderManufacture,
      {
        onSuccess({ manufactureDocumentNextStatus }) {
          setValue("aggrid_status", manufactureDocumentNextStatus?.status_name);
          setValue("main_status", manufactureDocumentNextStatus?.status_name);
          enqueueSnackbar("ยืนยันใบสั่งผลิตสำเร็จ", {
            variant: "success",
          });
        },
        onError() {},
      }
    );

  useEffect(() => {
    switch (status) {
      case "pending_manu":
      case "in_progress":
      case "finished":
      case "cancelled":
        setDisabled(true);
        setShowButton(false);
        break;
      default:
        setDisabled(false);
        setShowButton(true);
    }
  }, [status]);

  const ingredientQtyCalculation = useCallback(
    async (isUpdateQty?: boolean) => {
      const getBom = await bomRefetch();
      const bom = getBom?.data?.bom as IBom;

      const { ingredient_list: bomIngredientList, mfg_qty } = bom;

      if (!mfg_qty || parseFloat(mfg_qty) <= 0) return;

      const currentProductionQty = Number(getValues("production_qty")) || 0;
      const moIngredientList = getValues("ingredient_list") || [];
      const newMfgQty = parseFloat(mfg_qty);
      const ratio = currentProductionQty / newMfgQty;
      const recommendProductionQty = ratio * newMfgQty;
      setRoundedProductionQty(recommendProductionQty);

      // Get unique item IDs from BOM
      const bomItemIds = (bomIngredientList ?? []).map(
        (item) => item?.item_unique_id
      );

      // Request item SKU details
      const client: GraphQLClient = createGraphQLClientWithMiddleware("item");
      const { itemSkuDetailsFindManyAggrid } = await client.request(
        ITEMS_SKU_AGGRID,
        {
          aggridInput: {
            startRow: 0,
            endRow: bomItemIds.length,
            filterModel: {
              sku_name: {
                filterType: "set",
                values: bomItemIds,
              },
            },
          },
        }
      );

      const { data: itemSkuDetails } = await itemSkuDetailsFindManyAggrid;

      // Helper: enrich ingredients with SKU details
      const mapIngredientsWithSku = (
        ingredients: IIngredient[]
      ): IIngredient[] =>
        ingredients.map((ingredient) => {
          const itemDetail = itemSkuDetails.find(
            (realItem: any) => realItem.sku_name === ingredient?.item_unique_id
          );
          return {
            ...ingredient,
            item_sku_qty: {
              stock_qty: itemDetail?.stock_qty,
              available_qty: itemDetail?.available_qty,
              purchase_ordered_qty: itemDetail?.purchase_ordered_qty,
              manufacture_ordered_qty: itemDetail?.manufacture_ordered_qty,
              sale_committed_qty: itemDetail?.sale_committed_qty,
              manufacture_committed_qty: itemDetail?.manufacture_committed_qty,
            },
          };
        });

      // Format BOM ingredient list
      const formattedBomList = mapIngredientsWithSku(bomIngredientList ?? []);

      // Create a Set of the new BOM's CUIDs for quick lookup
      const newBomCuidSet = new Set(
        formattedBomList.map((bomIng) => bomIng.cuid)
      );

      // Create a Map of MO ingredients for quick lookup
      const moIngredientMap = new Map(
        moIngredientList.map((moIng) => [moIng.reference_line_item_cuid, moIng])
      );

      // Create the updated ingredient list
      const updatedIngredientList: IIngredient[] = [];

      // Process BOM ingredients (update or add new ones while preserving previous values)
      formattedBomList.forEach((bomIngredient) => {
        const existingMoIngredient = moIngredientMap.get(bomIngredient.cuid);

        const qty = ratio * (Number(bomIngredient.qty) || 0);
        const roundedQty = roundingNumber(qty);

        // ✅ Preserves previous values if `existingMoIngredient` exists, otherwise uses `bomIngredient`
        const newIngredient = {
          ...(existingMoIngredient ? existingMoIngredient : bomIngredient),
          cuid: undefined,
          qty: isUpdateQty
            ? roundedQty.toString()
            : existingMoIngredient?.qty?.toString() ?? roundedQty.toString(),
          snapshot_qty: roundedQty,
          unrounded_qty: qty,
          reference_line_item_cuid: bomIngredient.cuid, // Keep reference
        };

        updatedIngredientList.push(newIngredient);
      });

      // Process MO ingredients (keep only those that are NOT linked to an old BOM item)
      moIngredientList.forEach((moIngredient) => {
        const isOldBomItem = moIngredient.reference_line_item_cuid
          ? !newBomCuidSet.has(moIngredient.reference_line_item_cuid)
          : false;

        if (
          !isOldBomItem &&
          !updatedIngredientList.some(
            (item) =>
              item.reference_line_item_cuid ===
              moIngredient.reference_line_item_cuid
          )
        ) {
          updatedIngredientList.push(moIngredient); // Keep only valid MO ingredients
        }
      });

      // Replace ingredient_list in React Hook Form and trigger re-render
      setValue("ingredient_list", updatedIngredientList, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [bomRefetch, getValues, setValue]
  );

  const fetchMo = useCallback(async () => {
    if (isSuccessMo) {
      const moType = await formatQueryManufactureOrder(moData.manufactureOrder);
      const ingredientUniqueIdList: string[] =
        moData?.manufactureOrder?.ingredient_list?.map(
          (ingredient: any) => ingredient.item_unique_id
        ) as string[];
      const wasteUniqueIdList: string[] =
        moData?.manufactureOrder?.waste_production_list?.map(
          (waste: any) => waste.item_unique_id
        ) as string[];
      setIngredientItemIds(ingredientUniqueIdList);
      setIngredientItemIdsSnapshot(ingredientUniqueIdList);
      setWasteProductionItemIds(wasteUniqueIdList);
      setWasteProductionItemIdsSnapshot(wasteUniqueIdList);
      ingredientQtyCalculation();
      reset({ ...moType });
    }
  }, [ingredientQtyCalculation, isSuccessMo, moData?.manufactureOrder, reset]);

  useEffect(() => {
    const localState = localStorage.getItem("mo state");

    if (id) {
      fetchMo();
    } else if (state) {
      const { copied_id, copied_unique_id, ...otherState } = state;
      reset((prev) => ({ ...prev, ...otherState }));
    } else if (localState) {
      reset((prev) => ({ ...prev, ...JSON.parse(localState) }));
    }
    localStorage.removeItem("mo state");
  }, [fetchMo, id, reset, state]);

  // useEffect(() => {
  //   if (dirtyFields.attachment_list) {
  //     reset({}, { keepValues: true, keepDirty: false });
  //   }
  // }, [dirtyFields.attachment_list, reset]);

  useEffect(() => {
    if (watchBomName) {
      setValue("bom_id", bomData?.bom?.id);
      // setValue("bom_detail", bomData?.bom);
    }
  }, [bomData?.bom?.id, setValue, watchBomName]);

  // const halfRoundDecimal = (qty: number) => {
  //   // const newQty = qty.toFixed(4);
  //   // const absNumber = Math.floor(qty);
  //   // const decimal = parseFloat(newQty) - absNumber;
  //   // if (decimal === 0) return absNumber;
  //   // else if (decimal < 0.5) return absNumber + 0.5;
  //   // else
  //   return Math.ceil(qty);
  // };

  const checkLowerQtyModal = (data: IManufactureOrder): boolean => {
    if (data?.ingredient_list && data?.ingredient_list.length > 0) {
      const isLower = data?.ingredient_list?.some((ingredient) => {
        if (ingredient.qty && ingredient.snapshot_qty)
          return parseInt(ingredient.qty) < ingredient.snapshot_qty;
        else return false;
      });
      return isLower || false;
    } else return false;
  };

  const copyDocumentHandler = () => {
    const manufactureOrder = getValues();
    navigate("/manufacture/order/add", {
      state: copyManufactureOrderformatter(manufactureOrder),
    });
  };

  const handleClickOpenItem = async (unique_id?: string | null) => {
    if (unique_id && typeof unique_id === "string") {
      window.open(
        `/inventory/items/${unique_id}?tab=item&subtab=general`,
        "_blank"
      );
    } else {
      const unique_id = getValues("item_unique_id");
      if (unique_id && unique_id !== "")
        window.open(
          `/inventory/items/${unique_id}?tab=item&subtab=general`,
          "_blank"
        );
    }
  };

  const acceptConfirmationAction = async () => {
    const data = getValues();
    setAllLoading(true);
    const { unique_id, ...otherData } = await formatterManufactureOrder(data);
    setAllLoading(false);
    setChangeStatus(true);
    if (!id) {
      const { manufactureOrderCreate } = await createMo({
        createInput: {
          ...otherData,
          unique_id,
          sub_status: "pending_manu",
          ingredient_list: otherData.ingredient_list?.map(
            ({ cuid, ...other }) => other
          ),
          waste_production_list: otherData.waste_production_list?.map(
            ({ cuid, ...other }) => other
          ),
        },
      });
      if (manufactureOrderCreate) {
        try {
          await manufactureActivityLogCreate({
            activity_type: ActivityType.Create,
            document_type: ManufactureActivityLogDocumentType.ManufactureOrder,
            reference_id: manufactureOrderCreate.id,
            activity_detail: {},
          });
          if (state && state.copied_unique_id) {
            await manufactureActivityLogCreate({
              activity_type: ActivityType.Copy,
              document_type:
                ManufactureActivityLogDocumentType.ManufactureOrder,
              reference_id: manufactureOrderCreate.id,
              activity_detail: {
                copied_from: {
                  id: state.copied_id,
                  unique_id: state.copied_unique_id,
                },
                copied_to: {
                  id: manufactureOrderCreate.id,
                  unique_id: manufactureOrderCreate.unique_id,
                },
              },
            });
          }
          await manufactureActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: ManufactureActivityLogDocumentType.ManufactureOrder,
            reference_id: manufactureOrderCreate.id,
            activity_detail: {
              curr_status: Status.PendingManu,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      const { manufactureOrderUpdate } = await updateMo({
        uniqueInput: { unique_id: unique_id },
        updateInput: { ...otherData, sub_status: "pending_manu" },
      });
      if (manufactureOrderUpdate) {
        try {
          await manufactureActivityLogCreate({
            activity_type: ActivityType.StatusChange,
            document_type: ManufactureActivityLogDocumentType.ManufactureOrder,
            reference_id: manufactureOrderUpdate.id,
            activity_detail: {
              curr_status: Status.PendingManu,
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    await refetch();
  };

  const lowerQtyConfirmationAction = async (data: IManufactureOrder) => {
    if (!data.sub_status || data.sub_status === "draft") {
      if (changeStatus) {
        submitHandler(data, true);
      } else {
        saveHandler(data, true);
      }
    } else {
      saveHandler(data, true);
    }
  };

  const saveHandler = async (
    data: IManufactureOrder,
    isAcceptQty?: boolean
  ) => {
    let bomData: BomQuery | undefined = undefined;
    setChangeStatus(false);
    setAllLoading(true);
    const { unique_id, ...otherData } = await formatterManufactureOrder(data);
    setAllLoading(false);
    const { ingredient_list, waste_production_list } = otherData;
    if (watchBomName && watchBomName !== "") {
      const { data: bomFetch } = await bomRefetch();
      bomData = bomFetch;
    }
    if (
      bomData &&
      bomData.bom?.item_unique_id !== data.item_unique_id &&
      data.sub_status === "draft"
    ) {
      openItemChangeConfirmationHandler();
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        if (!id) {
          const { manufactureOrderCreate } = await createMo({
            createInput: {
              ...otherData,
              unique_id,
              sub_status: "draft",
              ingredient_list: ingredient_list?.map(
                ({ cuid, ...other }) => other
              ),
              waste_production_list: waste_production_list?.map(
                ({ cuid, ...other }) => other
              ),
            },
          });
          if (manufactureOrderCreate) {
            try {
              await manufactureActivityLogCreate({
                activity_type: ActivityType.Create,
                document_type:
                  ManufactureActivityLogDocumentType.ManufactureOrder,
                reference_id: manufactureOrderCreate.id,
                activity_detail: {},
              });
              if (state && state.copied_unique_id) {
                await manufactureActivityLogCreate({
                  activity_type: ActivityType.Copy,
                  document_type:
                    ManufactureActivityLogDocumentType.ManufactureOrder,
                  reference_id: manufactureOrderCreate.id,
                  activity_detail: {
                    copied_from: {
                      id: state.copied_id,
                      unique_id: state.copied_unique_id,
                    },
                    copied_to: {
                      id: manufactureOrderCreate.id,
                      unique_id: manufactureOrderCreate.unique_id,
                    },
                  },
                });
              }
              await manufactureActivityLogCreate({
                activity_type: ActivityType.StatusChange,
                document_type:
                  ManufactureActivityLogDocumentType.ManufactureOrder,
                reference_id: manufactureOrderCreate.id,
                activity_detail: {
                  curr_status: Status.Draft,
                },
              });
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          const { manufactureOrderUpdate } = await updateMo({
            uniqueInput: { unique_id: unique_id },
            updateInput: otherData,
          });
          const changedData = Object.keys(dirtyFields);
          if (changedData.length > 0 && manufactureOrderUpdate) {
            try {
              await manufactureActivityLogCreate({
                activity_type: ActivityType.Edit,
                document_type:
                  ManufactureActivityLogDocumentType.ManufactureOrder,
                reference_id: manufactureOrderUpdate.id,
                activity_detail: {},
              });
            } catch (error) {
              console.log(error);
            }
          }
        }
        await refetch();
      } else {
        openLowerQtyConfirmationHandler();
      }
    }
  };

  const updateWorkOrderStatusHandler = async (data: IManufactureOrder) => {
    setAllLoading(true);
    const { unique_id, work_order_list } = await formatterManufactureOrder(
      data
    );
    setAllLoading(false);
    setChangeWorkOrderStatus(true);
    updateMo({
      uniqueInput: { unique_id: unique_id },
      updateInput: { work_order_list },
    });
  };

  const submitHandler = async (
    data: IManufactureOrder,
    isAcceptQty?: boolean
  ) => {
    setChangeStatus(true);
    let bomData: BomQuery | undefined = undefined;
    if (watchBomName && watchBomName !== "") {
      const { data: bomFetch } = await bomRefetch();
      bomData = bomFetch;
    }
    if (
      bomData &&
      bomData.bom?.item_unique_id !== data.item_unique_id &&
      data.sub_status === "draft"
    ) {
      openItemChangeConfirmationHandler();
    } else {
      const isLower = checkLowerQtyModal(data);
      if (isAcceptQty || !isLower) {
        openAcceptConfirmationHandler();
      } else {
        openLowerQtyConfirmationHandler();
      }
    }
  };

  const {
    confirmation: lowerQtyConfirmation,
    openConfirmationHandler: openLowerQtyConfirmationHandler,
    closeConfirmationHandler: closeLowerQtyConfirmationHandler,
    submitConfirmationHandler: submitLowerQtyConfirmationHandler,
  } = useConfirmation(handleSubmit((data) => lowerQtyConfirmationAction(data)));

  const {
    confirmation: itemChangeConfirmation,
    openConfirmationHandler: openItemChangeConfirmationHandler,
    closeConfirmationHandler: closeItemChangeConfirmationHandler,
    submitConfirmationHandler: submitItemChangeConfirmationHandler,
  } = useConfirmation();

  const {
    confirmation: acceptConfirmation,
    openConfirmationHandler: openAcceptConfirmationHandler,
    closeConfirmationHandler: closeAcceptConfirmationHandler,
    submitConfirmationHandler: submitAcceptConfirmationHandler,
  } = useConfirmation(acceptConfirmationAction);

  useManufactureError(errors);

  if (
    id &&
    (isLoading ||
      isCreating ||
      isUpdating ||
      isChangingStatus ||
      (Boolean(watchBomName) && isBomLoading))
  ) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 300px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <form>
      <LoadingMascot isLoading={allLoading} />
      <ManufactureOrderHeaderForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        setDisabled={setDisabled}
        copyDocumentHandler={copyDocumentHandler}
        refetch={refetch}
        // currentState={state?.id ?? manufactureOrder}
      />
      <ManufactureOrderDetailForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
        roundedProductionQty={roundedProductionQty}
        ingredientReplace={ingredientReplace}
        workOrderReplace={workOrderReplace}
        ingredientQtyCalculation={ingredientQtyCalculation}
        handleClickOpenItem={handleClickOpenItem}
      />
      <ManufactureOrderTableForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        ingredientFields={ingredientFields}
        ingredientAppend={ingredientAppend}
        ingredientMove={ingredientMove}
        ingredientReplace={ingredientReplace}
        ingredientRemove={ingredientRemove}
        workOrderFields={workOrderFields}
        workOrderMove={workOrderMove}
        workOrderAppend={workOrderAppend}
        workOrderRemove={workOrderRemove}
        workOrderUpdate={workOrderUpdate}
        workOrderReplace={workOrderReplace}
        handleClickOpenItem={handleClickOpenItem}
        disabled={disabled}
        ingredientItemIds={ingredientItemIds}
        setIngredientItemIds={setIngredientItemIds}
        ingredientItemIdsSnapshot={ingredientItemIdsSnapshot}
        setIngredientItemIdsSnapshot={setIngredientItemIdsSnapshot}
        wasteProductionItemIds={wasteProductionItemIds}
        setWasteProductionItemIds={setWasteProductionItemIds}
        wasteProductionItemIdsSnapshot={wasteProductionItemIdsSnapshot}
        setWasteProductionItemIdsSnapshot={setWasteProductionItemIdsSnapshot}
        workOrderUpdateSubmit={handleSubmit((data) =>
          updateWorkOrderStatusHandler(data)
        )}
      />
      <ManufactureOrderRemarkForm
        control={control}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        disabled={disabled}
      />
      <ManufactureOrderAttachment
        control={control}
        errors={errors}
        setValue={setValue}
        disabled={disabled}
        getValues={getValues}
      />
      <Confirmation
        title={t("manufacture.order.sentence.accept_header")}
        message={t("manufacture.order.sentence.lower_qty_message")}
        open={lowerQtyConfirmation}
        handleClose={closeLowerQtyConfirmationHandler}
        action={submitLowerQtyConfirmationHandler}
        maxWidth={"sm"}
      />
      <Confirmation
        title={t("manufacture.order.sentence.change_bom_item_header")}
        message={t("manufacture.order.sentence.change_bom_item_message")}
        open={itemChangeConfirmation}
        handleClose={closeItemChangeConfirmationHandler}
        action={submitItemChangeConfirmationHandler}
        maxWidth={"sm"}
      />
      <Confirmation
        title={t("manufacture.order.sentence.accept_header")}
        message={t("manufacture.order.sentence.accept_message")}
        open={acceptConfirmation}
        handleClose={closeAcceptConfirmationHandler}
        action={submitAcceptConfirmationHandler}
      />
      {(showButton || (id && !disabled && !showButton)) && (
        <BottomNavbar>
          {showButton && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.save_draft")}
                onClick={handleSubmit((data) => saveHandler(data, false))}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.confirm")}
                onClick={handleSubmit((data) => submitHandler(data, false))}
              />
            </Stack>
          )}
          {id && !disabled && !showButton && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomizedButton
                variant="outlined"
                title={t("button.cancel")}
                onClick={() => navigate("/manufacture/order")}
              />
              <CustomizedButton
                variant="contained"
                title={t("button.save")}
                onClick={handleSubmit((data) => saveHandler(data, false))}
              />
            </Stack>
          )}
        </BottomNavbar>
      )}
    </form>
  );
};

export default DocumentInfoTab;
