import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IDefaultForm } from "../../../../types/global";
import { useGoodsAdjustmentItemList } from "../../../../hooks/Inventory/use-adjustment-item-list";
import Confirmation from "../../../UI/Confirmation";
import { useConfirmation } from "../../../../hooks/use-confrimation";
import { useStateContext } from "../../../../contexts/auth-context";
import { Fragment, useCallback, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { IBarcodeForm, ITraceEntry } from "../../../../types/Inventory";
import {
  InventoryDocumentType,
  ItemSkuQtysQuery,
  Tracability,
  TraceEntriesFindManyAggridQuery,
  WarehouseBinLocation,
  useItemSkuQtysQuery,
  useTraceEntriesFindManyAggridQuery,
} from "../../../../generated/inventory";
import ControlledTextField from "../../../Controller/ControlledTextField";
import { goodsAdjustmentTraceEntryFormatter } from "../../../../utils/Formatter/GoodsAdjustment";
import { IGoodsAdjustment } from "../../../../types/Inventory/goodsAdjustment";
import {
  formatDate,
  formatDateTimeNoAMPM,
} from "../../../../utils/Formatter/Date";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import TotalPostedQuantityCell from "./TotalPostedQuantity";
import CustomizedAvatar from "../../../Custom/CustomizedAvatar";
import ClearIcon from "@mui/icons-material/Clear";
import SerialList from "./SerialList";
import { useSnackbar } from "notistack";
import ControlledSelect from "../../../Controller/ControlledSelect";
import TotalStockQuantityCell from "./TotalStockQuantityCell";
import NewTotalStockQuantityCell from "./NewTotalStockQuantityCell";
import NewStockQuantityCell from "./NewStockQuantityCell";
import useBarcodeScanner from "../../../../hooks/Inventory/use-barcode-scanner";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { QrReader } from "react-qr-reader";
import { GraphQLClient } from "graphql-request";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { formatNumber } from "../../../../utils/dataTransformer";
import ControlledNumberTextField from "../../../Controller/ControlledNumberTextField";

type Props = IDefaultForm<IGoodsAdjustment> & {
  initialTraceItems?: string[];
  existingInitialTraceItems?: string[];
};

const GoodsAdjustmentItemList = ({
  control,
  errors,
  disabled,
  getValues,
  initialTraceItems,
  existingInitialTraceItems,
}: Props) => {
  const {
    state: { authUser },
  } = useStateContext();
  const [deletedIndex, setDeletedIndex] = useState<number | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();

  const headers = useGoodsAdjustmentItemList(disabled);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "trace_entry_list",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const watchSourceWarehouseUniqueId = useWatch({
    control,
    name: "source_warehouse_unique_id",
  });

  const watchMainStatus = useWatch({
    control,
    name: "main_status",
  });

  const deleteItemHandler = () => {
    remove(deletedIndex);
  };

  const {
    confirmation,
    openConfirmationHandler,
    closeConfirmationHandler,
    submitConfirmationHandler,
  } = useConfirmation(deleteItemHandler);

  const {
    control: barcodeControl,
    reset: resetBarcode,
    watch: watchBarcode,
    setValue: setBarcodeValue,
  } = useForm<IBarcodeForm>({
    defaultValues: {
      barcode: "",
      barcodeMobile: "",
    },
  });

  const graphQLClientWithHeaderItem: GraphQLClient =
    createGraphQLClientWithMiddleware("item");

  const { refetch } =
    useTraceEntriesFindManyAggridQuery<TraceEntriesFindManyAggridQuery>(
      graphQLClientWithHeaderItem,
      {
        aggridInput: {
          startRow: 0,
          endRow: 1,
          filterModel: {
            barcode: {
              filterType: "text",
              type: "equals",
              filter:
                watchBarcode("barcodeMobile")?.trim() ||
                watchBarcode("barcode").trim(),
            },
          },
        },
      },
      {
        enabled: false,
      }
    );

  const { refetch: refetchSkuQty } = useItemSkuQtysQuery<ItemSkuQtysQuery>(
    graphQLClientWithHeaderItem,
    {
      findManyInput: {
        where: {
          barcode: {
            equals:
              watchBarcode("barcodeMobile")?.trim() ||
              watchBarcode("barcode").trim(),
          },
        },
      },
    },
    {
      enabled: false,
    }
  );

  const onBarcodeSubmitHandler = useCallback(async () => {
    const { data } = await refetch();
    const { data: skuQtyData } = await refetchSkuQty();
    const warehouse_unique_id = getValues("source_warehouse_unique_id");
    if (
      fields &&
      fields.length > 0 &&
      fields.some(
        (trace) =>
          trace.item_unique_id === skuQtyData?.itemSkuQtys?.[0]?.sku_name
      )
    ) {
      enqueueSnackbar(`QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`, {
        variant: "error",
        style: { whiteSpace: "pre-line" },
      });
    } else {
      if (skuQtyData?.itemSkuQtys) {
        //TODO: validation
        if (skuQtyData.itemSkuQtys.length > 0) {
          const filteredWarehouse = skuQtyData.itemSkuQtys.filter(
            (itemSkuQty) =>
              itemSkuQty?.warehouse_level_3?.sub_level_2?.sub_level_1
                ?.warehouse_unique_id === warehouse_unique_id
          );

          if (filteredWarehouse.length > 0) {
            const formatBinLocations = filteredWarehouse.map(
              (itemSku) =>
                ({
                  id: itemSku?.bin_id,
                  bin_name:
                    itemSku?.bin_location?.bin_name ?? itemSku?.bin_name,
                  sub_level_3_id: itemSku?.sub_level_3_id,
                  stock_qty: itemSku?.stock_qty,
                } as WarehouseBinLocation & {
                  stock_qty?: number;
                })
            );

            const traceEntries = data?.traceEntriesFindManyAggrid?.data;
            const traceEntry = traceEntries
              ? traceEntries.find(
                  (trace) => trace?.type === InventoryDocumentType.GoodsReceive
                )
              : null;
            if (traceEntry) {
              const traceEntryType = traceEntry as ITraceEntry;
              const formatTrace = goodsAdjustmentTraceEntryFormatter(
                traceEntryType,
                formatBinLocations,
                authUser
              );
              if (formatTrace) {
                if (formatTrace.tracability === Tracability.Serial) {
                  const foundExisting = fields.findIndex(
                    (trace) =>
                      trace.item_unique_id === formatTrace.item_unique_id
                  );
                  if (foundExisting === -1) {
                    // append new trace (serial view)
                    const {
                      posted_date,
                      serial_no,
                      source_bin_location,
                      source_bin_location_id,
                      scanned_by,
                      barcode,
                      ...otherTrace
                    } = formatTrace;

                    const formatTraceWithSerial = {
                      ...otherTrace,
                      serial_list: [
                        {
                          ...otherTrace,
                          posted_date,
                          serial_no,
                          source_bin_location,
                          source_bin_location_id,
                          scanned_by,
                          barcode,
                        },
                      ],
                    };
                    append(formatTraceWithSerial);
                  } else {
                    // append new serial
                    const traceEntryList = getValues(`trace_entry_list`);

                    const currentTrace = traceEntryList[foundExisting];

                    if (currentTrace && currentTrace.serial_list) {
                      const foundBarcode = currentTrace.serial_list.findIndex(
                        (serial) =>
                          serial.barcode ===
                          (watchBarcode("barcodeMobile")?.trim() ||
                            watchBarcode("barcode").trim())
                      );
                      if (foundBarcode === -1) {
                        update(foundExisting, {
                          ...currentTrace,
                          serial_list: [
                            ...currentTrace.serial_list,
                            formatTrace,
                          ],
                        });
                      } else {
                        enqueueSnackbar(
                          `QR/Barcode\nสินค้านี้ถูกสแกนและบันทึก\nลงรายการแล้ว`,
                          {
                            variant: "error",
                            style: { whiteSpace: "pre-line" },
                          }
                        );
                      }
                    }
                  }
                } else {
                  // append new trace (normal view)
                  append(formatTrace);
                }
                enqueueSnackbar(`สแกน SN :\n${formatTrace.barcode}\nสำเร็จ`, {
                  variant: "success",
                  style: { whiteSpace: "pre-line" },
                });
              }
            }
          } else {
            // Warehouse not match
            enqueueSnackbar(
              "QR/Barcode นี้ไม่อยู่ในคลังที่เลือกกรุณาสแกนใหม่",
              {
                variant: "error",
              }
            );
          }
        } else {
          // Barcode not found
          enqueueSnackbar("QR/Barcode นี้ไม่อยู่ในระบบกรุณาสแกนใหม่", {
            variant: "error",
          });
        }
      }
    }
    resetBarcode((prev) => ({
      ...prev,
      barcode: "",
    }));
  }, [
    append,
    authUser,
    enqueueSnackbar,
    fields,
    getValues,
    refetch,
    refetchSkuQty,
    resetBarcode,
    update,
    watchBarcode,
  ]);

  const { scrollRef, showCamera, setShowCamera, setScanData, setIsInterval } =
    useBarcodeScanner(onBarcodeSubmitHandler);

  return (
    <>
      <>
        {!isMobile && <Typography fontWeight="bold">สแกน Barcode</Typography>}
        <Grid container spacing={2} mt={1} mb={3}>
          {!isMobile ? (
            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
              <ControlledTextField
                label="Scan Barcode"
                control={barcodeControl}
                name="barcode"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey === false) {
                    onBarcodeSubmitHandler();
                  }
                }}
                disabled={disabled || !watchSourceWarehouseUniqueId}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={10}>
                <ControlledTextField
                  label="Scan Barcode"
                  control={barcodeControl}
                  name="barcode"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey === false) {
                      onBarcodeSubmitHandler();
                    }
                  }}
                  disabled={disabled || !watchSourceWarehouseUniqueId}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={disabled || !watchSourceWarehouseUniqueId}
                  onClick={() => setShowCamera(!showCamera)}
                >
                  <QrCodeScannerIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
        {isMobile ? (
          <Box display={showCamera ? "block" : "none"} my={2}>
            {showCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (!!result) {
                    setScanData(result.getText());
                    setIsInterval(true);
                    setBarcodeValue("barcodeMobile", result.getText());
                  }
                }}
                scanDelay={2000}
                containerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoContainerStyle={{
                  width: "calc(100vw-48px)",
                  height: "100%",
                  contentVisibility: "auto",
                }}
                videoStyle={{
                  height: "100%",
                  contentVisibility: "auto",
                }}
              />
            )}
          </Box>
        ) : null}
      </>
      <TableContainer ref={scrollRef}>
        <Table
          sx={{ minWidth: 650, overflow: "scroll" }}
          aria-label="simple table"
        >
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.light }}
          >
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  align={header.align ? header.align : "center"}
                  key={index}
                  sx={{
                    px: 1,
                    py: 1,
                  }}
                  width={header.width}
                >
                  <Typography
                    fontSize={14}
                    fontWeight={600}
                    width={header.width}
                  >
                    {header.thaiLabel}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((trace, index) => (
              <Fragment key={trace.id}>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[0]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{index + 1}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[1]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {formatDateTimeNoAMPM(trace.posted_date)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[2]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        minWidth: "90px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        alt={trace.item_name}
                        src={trace?.item_img_url ? trace.item_img_url[0] : ""}
                        sx={{
                          width: 60,
                          height: 60,
                          border: "1px solid #BEBEBE",
                          borderRadius: "2px",
                        }}
                        variant="square"
                      >
                        <ImageOutlinedIcon
                          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                          fontSize="medium"
                        />
                      </Avatar>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[3]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>
                      {trace.item_unique_id}
                    </Typography>
                    <Typography fontSize={14}>{trace.item_name}</Typography>{" "}
                    {watchMainStatus === "draft" &&
                      initialTraceItems?.includes(trace.item_unique_id) &&
                      !existingInitialTraceItems?.includes(
                        trace.item_unique_id
                      ) && (
                        <Typography
                          fontSize={12}
                          sx={{
                            mt: 0.5,
                            color: (theme) => theme.palette.error.main,
                          }}
                        >
                          สินค้านี้ถูกลบออกจากระบบแล้ว กรุณาลบสินค้า
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[4]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <Typography fontSize={14}>
                        {formatNumber(trace.stock_qty || 0)}
                      </Typography>
                    ) : (
                      <TotalStockQuantityCell
                        control={control}
                        nestedIndex={index}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      px: 1,
                      py: 1,
                    }}
                    width={
                      (headers && headers.length > 0
                        ? headers[5]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <>
                        {disabled ? (
                          <Typography fontSize={14}>
                            {formatNumber(trace.qty)}
                          </Typography>
                        ) : (
                          // <ControlledTextField
                          //   type="number"
                          //   control={control}
                          //   name={`trace_entry_list[${index}].qty`}
                          //   disabled={disabled}
                          //   error={Boolean(
                          //     errors &&
                          //       errors.trace_entry_list &&
                          //       errors.trace_entry_list[index] &&
                          //       errors.trace_entry_list[index].qty
                          //   )}
                          //   allowNegativeNumber
                          // />
                          <ControlledNumberTextField
                            control={control}
                            name={`trace_entry_list.${index}.qty`}
                            disabled={disabled}
                            error={Boolean(
                              errors &&
                                errors.trace_entry_list &&
                                errors.trace_entry_list[index] &&
                                errors.trace_entry_list[index].qty
                            )}
                            allowNegative
                            inputFontSize={14}
                          />
                        )}
                      </>
                    ) : (
                      <TotalPostedQuantityCell
                        control={control}
                        nestedIndex={index}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="right"
                    width={
                      (headers && headers.length > 0
                        ? headers[6]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      <NewStockQuantityCell control={control} index={index} />
                    ) : (
                      <NewTotalStockQuantityCell
                        control={control}
                        nestedIndex={index}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[7]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.uom?.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[8]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.serial_no}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[9]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.tracability === Tracability.Normal ? (
                      trace.all_bin_locations &&
                      trace.all_bin_locations.length > 1 &&
                      !disabled ? (
                        <ControlledSelect
                          control={control}
                          name={`trace_entry_list.${index}.source_bin_location_id`}
                          options={trace.all_bin_locations.map((bin) => ({
                            label: bin.bin_name,
                            value: bin.id,
                          }))}
                          onChange={(e: any) => {
                            const { id, ...otherFields } = fields[index];
                            update(index, {
                              ...otherFields,
                              stock_qty:
                                trace.all_bin_locations &&
                                trace.all_bin_locations.find(
                                  (bin) => bin.id === e.target.value
                                ).stock_qty,
                            });
                          }}
                          inputProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          MenuProps={{
                            style: {
                              fontSize: 14,
                            },
                          }}
                          helperTextSize={10}
                        />
                      ) : (
                        <Typography fontSize={14}>
                          {trace.source_bin_location?.bin_name}
                        </Typography>
                      )
                    ) : null}
                  </TableCell>
                  <TableCell
                    align="center"
                    width={
                      (headers && headers.length > 0
                        ? headers[10]?.width || 0
                        : 0) + 16
                    }
                  >
                    {trace.scanned_by && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CustomizedAvatar
                          avatars={[
                            {
                              unique_id: trace.scanned_by.user_unique_id || "",
                              first_name: trace.scanned_by.first_name || "",
                              last_name: trace.scanned_by.last_name || "",
                              img_url: trace.scanned_by.img_url
                                ? trace.scanned_by.img_url[0]
                                : "",
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    width={
                      (headers && headers.length > 0
                        ? headers[11]?.width || 0
                        : 0) + 16
                    }
                  >
                    <Typography fontSize={14}>{trace.barcode}</Typography>
                  </TableCell>
                  <TableCell>
                    {trace.tracability === Tracability.Normal && (
                      <Typography fontSize={14}>
                        {formatDate(trace.lot_date)}
                      </Typography>
                    )}
                  </TableCell>
                  {!disabled && (
                    <TableCell
                      align="center"
                      width={
                        (headers && headers.length > 0
                          ? headers[12]?.width || 0
                          : 0) + 16
                      }
                    >
                      <IconButton
                        onClick={() => {
                          openConfirmationHandler();
                          setDeletedIndex(index);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
                <SerialList
                  control={control}
                  errors={errors}
                  nestedIndex={index}
                  disabled={disabled}
                />
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirmation
        title="ยืนยันหากต้องการลบสินค้า"
        message="หากลบสินค้าแล้ว รายการ SN ที่สแกนไว้จะถูกลบทั้งหมด"
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default GoodsAdjustmentItemList;
